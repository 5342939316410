<template>

    <div class="con-wrap">
        <CarrotTitle title="전체 휴가"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div class="day-select">
                        <button class="btn btn-prev" @click="cal.movePrev">prev</button>
                        <button class="day btn-cal">{{ cal.ymd }}</button>
                        <button class="btn btn-next" @click="cal.moveNext">next</button>
                    </div>

                    <CarrotCalendar v-if="cal.isShow" :ymd="cal.ymd" :daysinfo="cal.dates"></CarrotCalendar>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotCalendar from '@/components/common/CarrotCalendar.vue'

export default {
    layout:"myPIMS",
    components: {
        CarrotCalendar,
    },
    setup() {
        const toast = useToast();

        const cal = reactive({
            isShow : false,

            ymd  : "",
            page : 1,
            rows : 1000,

            list : [], total : 0,
            holiday:[],
            dates : [],

            doSearch: () => {
                let params = {
                    ymd  : cal.ymd,
                    page : cal.page,
                    rows : cal.rows,
                };

                cal.dates = [];
                cal.isShow = false;
                axios.get('/rest/mypims/AllVacationList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        cal.list  = res.data.list;
                        cal.holiday  = res.data.holiday;
                        cal.total = res.data.total;

                        for(let i=0; i<cal.list.length; i++){
                            let irow = cal.list[i];

                            // 주말, 지정 공휴일 만큼 loop cnt 추가
                            for(let k=0; k<irow.days; k++){//주말 미표시
                              let cd = new Date(irow.sdate);
                              cd.setDate(cd.getDate() + k);
                              if(cd.getDay() ==6 ||cd.getDay() ==0 )
                                irow.days++;
                            }
                            irow.days= irow.days+irow.holiday_cnt;//지정공휴일표시

                            if( irow.days > 1 ){


                                for(let j=0; j<irow.days; j++){
                                    let cd = new Date(irow.sdate);
                                    let holiday_chk=false;

                                  cd.setDate(cd.getDate() + j);

                                    // 주말 미표시
                                    if(cd.getDay() ==6 ||cd.getDay() ==0 ){
                                      holiday_chk=true;
                                    }

                                    let nitm = { idx: irow.idx };
                                    nitm.cdate = [cd.getFullYear(),((cd.getMonth() + 1)>9 ? '' : '0') + (cd.getMonth() + 1),(cd.getDate()>9 ? '' : '0') + cd.getDate()].join('-');
                                    nitm.text="";
                                    nitm.text =  nitm.text+irow.ename + '(' + irow.kname + ') - ' + irow.utype_txt;

                                    //지정 공휴일 미표시
                                    for(let k=0; k<cal.holiday.length; k++)
                                      if(nitm.cdate==cal.holiday[k].hdate)
                                        holiday_chk=true;

                                    if(!holiday_chk)
                                      cal.dates.push(nitm);
                                }
                            } else {
                                let nitm = { idx: irow.idx };
                                nitm.cdate = irow.sdate;
                                nitm.text = irow.ename + '(' + irow.kname + ') - ' + (irow.utype_txt.replace('반차', ''));
                                cal.dates.push(nitm);
                            }

                        }

                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                    cal.isShow = true;
                })
            },

            selected: (node) => {
                console.info(node);
            },

            movePrev: ()=>{
                let cur = cal.ymd.split("-");
                let td = new Date(cur[0], (Number(cur[1])-1), 0);

                cal.ymd = td.getFullYear() + '-' + (td.getMonth()>8?td.getMonth()+1:'0'+(td.getMonth()+1));

                cal.doSearch();
            },

            moveNext: () => {
                let cur = cal.ymd.split("-");
                let td = new Date(cur[0], (Number(cur[1])+1), 0);

                cal.ymd = td.getFullYear() + '-' + (td.getMonth()>8?td.getMonth()+1:'0'+(td.getMonth()+1));

                cal.doSearch();
            }
        });

        
        onMounted(() => {
            // Mounted
            console.log(cal.ymd);
            let td = new Date();
            cal.ymd = td.getFullYear() + '-' + (td.getMonth()>8?td.getMonth()+1:'0'+(td.getMonth()+1));
            console.log(cal.ymd);

            cal.doSearch();
        });

        return {cal};
    }
}
</script>

<style lang="scss" scoped>
</style>